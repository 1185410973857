import gql from 'graphql-tag'

export default gql`{
  _id
  title
  description
  tip
  date
  about{
    title
    description
  }
}
`