import gql from 'graphql-tag'
import homeFragment from './HomeFragment'

export const CONTENT_HOME_QUERY = gql `
  query getContentHome{
    getContentHome{
      ...${homeFragment}
    }
  }
`
export const SLIDE_HOME_QUERY = gql `
  query getSlideHome{
    getSlideHome{
      _id
      name
    }
  }
`